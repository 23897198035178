import React from 'react';
import styles from '../media-gallery.module.css';
import VideoOrImage from '@vcc-www/video-or-image/src/VideoOrImage';
import { ASPECT_RATIO } from '../media-gallery.constants';
import { Sources } from '@vcc-www/utils/getMediaSources';
import { ResponsiveAspectRatio } from '../media-gallery.module';

export const MediaGalleryViewSliderItem = ({
  alt,
  imageFallbackUrl,
  imageSources,
  isCurrent,
  responsiveAspectRatio,
  videoCtaAutoId,
  videoSourcesMp4,
}: {
  alt: string | undefined;
  imageFallbackUrl?: string;
  imageSources: Sources;
  isCurrent: boolean;
  responsiveAspectRatio?: ResponsiveAspectRatio;
  videoCtaAutoId: string;
  videoSourcesMp4?: Sources;
}) => {
  const imgRef = React.useRef<HTMLImageElement>(null);

  function onErrorImageFallback() {
    if (imgRef.current && imageFallbackUrl) {
      imgRef.current.src = imageFallbackUrl;
      imgRef.current.srcset = '';
    }
  }

  return (
    <div className={`${styles.sliderItem}`}>
      <VideoOrImage
        altText={alt}
        aspectRatio={responsiveAspectRatio || ASPECT_RATIO}
        imageSources={imageSources}
        imageRef={imgRef}
        onErrorImageFallback={onErrorImageFallback}
        playPauseButtonTabIndex={isCurrent ? 0 : -1}
        shouldAutoplay={isCurrent}
        shouldPause={!isCurrent}
        sizes={{ default: '100vw', fromL: '70vw' }}
        videoCtaAutoId={videoCtaAutoId}
        videoSourcesMp4={videoSourcesMp4}
      />
    </div>
  );
};
