'use client';

import { SyntheticEvent } from 'react';
import {
  FALLBACK_IRONMARK_192,
  FALLBACK_IRONMARK_512,
  FALLBACK_WORDMARK_IMAGE,
} from '@utils/constants';
import {
  errorCategories,
  reportDetailedErrorMessage,
} from '@utils/error-logging/error-logging-messages';
import Image, { ImageProps } from 'next/image';

type ImageWithFallbackProps = ImageProps & {
  fallbackImage: 'iron-mark' | 'spread-word-mark';
  fallbackSize?: 'S' | 'M';
  sourceComponent: string;
};

function getFallbackImage(
  fallbackImage: ImageWithFallbackProps['fallbackImage'],
  fallbackSize?: ImageWithFallbackProps['fallbackSize'],
) {
  if (fallbackImage === 'iron-mark') {
    if (fallbackSize) {
      return fallbackSize === 'S'
        ? FALLBACK_IRONMARK_192
        : FALLBACK_IRONMARK_512;
    }
    return FALLBACK_IRONMARK_192;
  }

  return FALLBACK_WORDMARK_IMAGE;
}

export function ImageWithFallback({
  fallbackImage,
  fallbackSize,
  sourceComponent,
  ...imageProps
}: ImageWithFallbackProps) {
  const fallbackImgUrl = getFallbackImage(fallbackImage, fallbackSize);

  function onError(e: SyntheticEvent<HTMLImageElement, Event>) {
    if (e.currentTarget.src !== fallbackImgUrl) {
      e.currentTarget.onerror = null;
      e.currentTarget.src = fallbackImgUrl;
      e.currentTarget.srcset = '';

      reportDetailedErrorMessage({
        culprit: `${sourceComponent} > ImageWithFallback`,
        errorCategory: errorCategories.GENERAL,
        details: {
          message: `Image loading failed with src "${imageProps.src}"`,
        },
      });
    }
  }

  return <Image {...imageProps} onError={onError} />;
}
