import { useRef } from 'react';
import type { PointerEvent, MutableRefObject } from 'react';
import { VIDEO_CTA_ID_PART } from './media-gallery.constants';

/**
 * Dedicated hook for desktop mouse behaviour
 * It handles the chevron behaviour when mouse
 * goes over the Media component
 *
 * @returns {Ref} cursorRef - Covers container where chevrons tracks user mouse indicating slide behaviour
 * @returns {Ref} chevronBackRef - Left chevron
 * @returns {Ref} chevronForwardRef - Right chevron
 * @returns {Ref} fakeScreenRef - Covers MediaGalleryViewPointerScreen. Detect when user hovers over CTAs to omit chevron behaviour
 * @returns {function} handlePointerEnter - Presents chevron container behaviour
 * @returns {function} handlePointerLeave - Omits chevron container behaviour
 * @returns {function} handlePointerMove - Monitors pointer movement in MediaGalleryViewPointerScreen
 */

export const useMediaGalleryPointer = () => {
  const cursorRef = useRef<null | HTMLDivElement>(null);
  const chevronBackRef = useRef<null | HTMLDivElement>(null);
  const chevronForwardRef = useRef<null | HTMLDivElement>(null);
  const fakeScreenRef = useRef<null | HTMLDivElement>(null);

  const handlePointerHoverCta = (elements: Element[], ctaId: string) => {
    return elements?.find((el) => {
      const elementWithDataAutoId = el?.attributes?.getNamedItem('data-autoid');
      return elementWithDataAutoId?.value?.includes(ctaId);
    });
  };

  const handlePointerLeaveCta = () => {
    if (fakeScreenRef?.current?.style?.display === 'none') {
      fakeScreenRef.current.style.display = 'flex';
    }
  };

  const handlePointerMove = ({
    event,
    handleSliderContainer,
    isMousePresent,
    inView,
    setIsMousePresent,
    viewportHalfWidth,
  }: {
    event: PointerEvent<HTMLDivElement>;
    handleSliderContainer: (clientXPosition: number) => {
      onLeftHalfViewport: boolean;
      onRightHalfViewport: boolean;
    } | null;
    isMousePresent: boolean;
    inView: boolean;
    setIsMousePresent: (present: boolean) => void;
    viewportHalfWidth: MutableRefObject<number | null>;
  }) => {
    if (cursorRef?.current && viewportHalfWidth?.current && event && inView) {
      let x = 0;
      let y = 0;
      let clientX = 0;
      let clientY = 0;

      const rect = event.currentTarget.getBoundingClientRect();
      clientX = event.clientX;
      clientY = event.clientY;
      x = clientX - rect.left;
      y = clientY - rect.top;

      cursorRef.current.style.transform = `translate3d(${x}px, ${y}px, 0)`;

      // Mouse activity from users device
      if (!isMousePresent && event?.pointerType === 'mouse') {
        setIsMousePresent(true);
      }

      // Prev/next arrow show.
      if (chevronBackRef?.current && chevronForwardRef?.current) {
        const viewportPosition = handleSliderContainer(x);

        if (!viewportPosition) {
          return;
        }

        const { onLeftHalfViewport, onRightHalfViewport } = viewportPosition;
        const isChevronBackHidden =
          chevronBackRef.current.style.display === 'none';
        const isChevronForwardHidden =
          chevronForwardRef.current.style.display === 'none';

        if (onLeftHalfViewport && isChevronBackHidden) {
          chevronForwardRef.current.style.display = 'none';
          chevronBackRef.current.style.display = 'flex';
        }

        if (onRightHalfViewport && isChevronForwardHidden) {
          chevronBackRef.current.style.display = 'none';
          chevronForwardRef.current.style.display = 'flex';
        }
      }

      const elements = document.elementsFromPoint(clientX, clientY);
      const videoButton = handlePointerHoverCta(elements, VIDEO_CTA_ID_PART);

      // Hide cursor if it's over CTA.
      if (!!videoButton && fakeScreenRef?.current) {
        fakeScreenRef.current.style.display = 'none';
      }

      !!videoButton &&
        videoButton.addEventListener('pointerleave', () =>
          handlePointerLeaveCta(),
        );
    }
  };

  const handlePointerEnter = () => {
    if (cursorRef?.current) {
      cursorRef.current.style.opacity = '1';
    }
  };

  const handlePointerLeave = () => {
    if (cursorRef?.current) {
      cursorRef.current.style.opacity = '0';
    }
  };

  return {
    cursorRef,
    chevronBackRef,
    chevronForwardRef,
    fakeScreenRef,
    handlePointerEnter,
    handlePointerLeave,
    handlePointerMove,
  };
};
