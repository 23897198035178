import React from 'react';
import type { PointerEvent, MouseEventHandler, MutableRefObject } from 'react';

import styles from '../media-gallery.module.css';

export const MediaGalleryViewPointerScreen = ({
  fakeScreenHeight,
  fakeScreenRef,
  handlePointerEnter,
  handlePointerLeave,
  onClick,
  onPointerMove,
}: {
  fakeScreenHeight: number;
  fakeScreenRef: MutableRefObject<HTMLDivElement | null>;
  handlePointerEnter: () => void;
  handlePointerLeave: () => void;
  onClick: MouseEventHandler<HTMLDivElement>;
  onPointerMove: (event: PointerEvent<HTMLDivElement>) => void;
}) => {
  return (
    <div
      aria-hidden
      className={`${styles.pointerScreen}`}
      ref={fakeScreenRef}
      onClick={onClick}
      onPointerMove={onPointerMove}
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
      style={{ height: `${fakeScreenHeight}px` }}
    />
  );
};
