import React from 'react';

export const MediaGalleryViewSliderCounter = ({
  currentIndex,
  isRTL,
  totalItems,
}: {
  currentIndex: number;
  isRTL: boolean;
  totalItems: number;
}) => {
  const counterContent = isRTL
    ? `${totalItems}/${currentIndex}`
    : `${currentIndex}/${totalItems}`;
  return (
    <div className="flex-row justify-center mt-8" data-testid="media-counter">
      <p>{counterContent}</p>
    </div>
  );
};
