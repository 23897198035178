'use client';

import React, { useState } from 'react';
import type { MouseEvent, PointerEvent } from 'react';
import { Icon } from '@volvo-cars/react-icons';
import type { ThemeBreakpointName } from 'vcc-ui';
import { useKeyPress } from '@volvo-cars/react-utils';
import { useSpringCarousel } from 'react-spring-carousel';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { Sources } from '@vcc-www/utils/getMediaSources';
import { useTracker } from '@volvo-cars/tracking';
import { useMediaGalleryViewport } from './media-gallery-viewport.hook';
import { useMediaGalleryPointer } from './media-gallery-pointer.hook';
import { VIDEO_CTA_ID_PART } from './media-gallery.constants';
import { MediaGalleryViewPointerScreen } from './media-gallery-views/media-gallery-view.pointer-screen';
import { MediaGalleryViewSliderItem } from './media-gallery-views/media-gallery-view.slider-item';
import { MediaGalleryViewSliderCounter } from './media-gallery-views/media-gallery-view.slider-counter';

import styles from './media-gallery.module.css';

export type MediaItemsProps = {
  alt?: string;
  body?: string;
  imageSources: Sources;
  videoSourcesMp4?: Sources;
  videoId?: string;
};

export type ResponsiveAspectRatio = {
  [key in ThemeBreakpointName]?: number[];
} & { default: number[] };

export type MediaGalleryProps = {
  imageFallbackUrl?: string;
  isRtl?: boolean; // For testing purposes ONLY
  isModalOpen?: boolean;
  mediaItems: Array<MediaItemsProps>;
  responsiveAspectRatio?: ResponsiveAspectRatio;
};

export const MediaGallery = ({
  imageFallbackUrl,
  isRtl,
  isModalOpen, // Used to determine if the component is wrapped by a modal and if said modal is open
  mediaItems,
  responsiveAspectRatio,
}: MediaGalleryProps) => {
  const { languageDirection } = useCurrentMarketSite();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMousePresent, setIsMousePresent] = useState(false);

  const tracker = useTracker(null, { mode: 'ga4' });
  const isRTL = isRtl ?? languageDirection === 'rtl';

  const isWrappedInModal = isModalOpen !== undefined;
  const { handleSliderContainer, inView, inViewRef, viewportHalfWidth } =
    useMediaGalleryViewport({ isModalOpen, isWrappedInModal });

  const {
    cursorRef,
    chevronBackRef,
    chevronForwardRef,
    fakeScreenRef,
    handlePointerEnter,
    handlePointerLeave,
    handlePointerMove,
  } = useMediaGalleryPointer();

  const enableCarouselSlideEffects = mediaItems.length > 1;

  const {
    carouselFragment,
    slideToPrevItem,
    slideToNextItem,
    getIsAnimating,
    useListenToCustomEvent,
  } = useSpringCarousel({
    items: mediaItems.map(({ alt, imageSources, videoSourcesMp4 }, i) => {
      const isCurrent = currentIndex === i;
      return {
        id: `item-${i + 1}`,
        renderItem: (
          <MediaGalleryViewSliderItem
            alt={alt}
            imageFallbackUrl={imageFallbackUrl}
            imageSources={imageSources}
            isCurrent={isCurrent}
            responsiveAspectRatio={responsiveAspectRatio}
            videoCtaAutoId={`${VIDEO_CTA_ID_PART}-${i}`}
            videoSourcesMp4={videoSourcesMp4}
          />
        ),
      };
    }),
    ...(enableCarouselSlideEffects ? { withLoop: true } : {}),
  });

  useListenToCustomEvent((e) => {
    if (e.eventName === 'onSlideStartChange') {
      const {
        nextItem: { index },
      } = e;
      setCurrentIndex(index);
    }
  });

  const handleClick = (isPrev?: boolean) => {
    if (inView) {
      if (isPrev) {
        tracker.customEvent({
          eventAction: 'button|click',
          eventCategory: 'MediaGallery',
          eventLabel: 'left arrow',
        });
        tracker.customEvent({
          eventAction: 'arrow|previous',
          eventCategory: 'MediaGallery',
          eventLabel: 'previous',
        });
        !getIsAnimating() && slideToPrevItem();
      } else {
        tracker.customEvent({
          eventAction: 'button|click',
          eventCategory: 'MediaGallery',
          eventLabel: 'right arrow',
        });
        tracker.customEvent({
          eventAction: 'arrow|next',
          eventCategory: 'MediaGallery',
          eventLabel: 'next',
        });
        !getIsAnimating() && slideToNextItem();
      }
    }
  };

  useKeyPress('ArrowLeft', () => handleClick(true));
  useKeyPress('ArrowRight', () => handleClick());

  const onClickPointerScreen = (event: MouseEvent) => {
    if (isMousePresent) {
      if (chevronBackRef?.current && chevronForwardRef?.current) {
        const previousSlide = chevronBackRef.current.style.display === 'flex';
        handleClick(previousSlide);
      }
    } else {
      const viewportPosition = handleSliderContainer(event.clientX);
      if (!viewportPosition) {
        return;
      }
      const { onLeftHalfViewport: previousSlide } = viewportPosition;
      handleClick(previousSlide);
    }
  };

  const onPointerMove = (event: PointerEvent<HTMLDivElement>) =>
    handlePointerMove({
      event,
      handleSliderContainer,
      isMousePresent,
      inView,
      setIsMousePresent,
      viewportHalfWidth,
    });

  const { props: carouselFragmentProps = {} } =
    carouselFragment as React.ReactElement;
  const fakeScreenHeight =
    carouselFragmentProps?.children?.ref?.current?.clientHeight || 0;

  return (
    <div className="mx-0" ref={inViewRef}>
      <div className={`${styles.relative} ${styles.hideOverflow}`}>
        {enableCarouselSlideEffects && (
          <>
            <div
              className={`${styles.mediaCursor} ${styles.opacityHidden}`}
              data-autoid="slidingGallery:navigationIcon"
              ref={cursorRef}
            >
              <div ref={chevronBackRef} style={{ display: 'none' }}>
                <Icon icon="chevron-back" size={16} />
              </div>
              <div ref={chevronForwardRef} style={{ display: 'none' }}>
                <Icon icon="chevron-forward" size={16} />
              </div>
            </div>
            <MediaGalleryViewPointerScreen
              fakeScreenHeight={fakeScreenHeight}
              fakeScreenRef={fakeScreenRef}
              handlePointerEnter={handlePointerEnter}
              handlePointerLeave={handlePointerLeave}
              onClick={onClickPointerScreen}
              onPointerMove={onPointerMove}
            />
          </>
        )}
        {carouselFragment}
        {enableCarouselSlideEffects && (
          <MediaGalleryViewSliderCounter
            currentIndex={currentIndex + 1}
            isRTL={isRTL}
            totalItems={mediaItems.length}
          />
        )}
      </div>
    </div>
  );
};

export default MediaGallery;
